/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
class RepositoryFactory {
  getRepository(serviceName) {
    const service = require(`../repositories/restful/${serviceName}.repository`);
    return service.default;
  }
}

export default new RepositoryFactory();
