<template>
  <q-layout
    v-if="loading"
    view="hHh LpR fff"
  >
    <q-page-container>
      <q-page class="column items-center">
        <div class="col row items-center">
          <q-circular-progress
            indeterminate
            size="lg"
            color="light-blue"
          />
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
  <ForgetPassword v-else-if="currentPath === '/forgot-password'" />
  <ConfirmMail v-else-if="currentPath === '/confirm-mail'" />
  <router-view v-else-if="authStore.isLoggedIn" v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component" />
    </transition>
  </router-view>
  <LoginPage v-else />
  <q-inner-loading :showing="isLoading || isLoadingAu">
    <q-spinner-pie size="50px" color="primary" />
  </q-inner-loading>
</template>
<script setup>
import {
  defineAsyncComponent, onMounted, ref, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import MemberService from 'src/shared/services/member.service';
import { useAuthStore } from './shared/store/authStore';
import { useAppStore } from './shared/store/appStore';

const route = useRouter();

const LoginPage = defineAsyncComponent(() => import('./pages/Auth/Login'));
const ForgetPassword = defineAsyncComponent(() => import('./pages/password/ForgetPassword'));
const ConfirmMail = defineAsyncComponent(() => import('./pages/Confirm/ConfirmMail'));
const authStore = useAuthStore();
const { isLoading, isLoadingAu } = useAppStore();
const loading = ref(true);
const currentPath = ref('/');
const { currentRoute } = route;

watch(currentRoute, async (value) => {
  currentPath.value = value.path;
  await MemberService.checklogin();
});
onMounted(async () => {
  loading.value = false;
});

</script>
<style>
@import url('./assets/tailwind');
</style>
