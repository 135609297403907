import { Notify } from 'quasar';

class ToastUtil {
  success(message) {
    Notify.create({
      type: 'positive',
      message,
    });
  }

  warning(message) {
    Notify.create({
      type: 'warning',
      message,
    });
  }

  error(message) {
    Notify.create({
      type: 'negative',
      message,
    });
  }

  exception(error) {
    if (error instanceof Error) {
      if (error.code === 'ECONNABORTED' || error.statusCode === 503) { // timeout
        this.error('通信タイムアウトが発生しました。しばらく経ってからもう一度お試しください。');
      } else if (error.code === 'API_ERROR') { // Api Error
        this.error('サーバーエラーが発生しました。システム担当者にお問合せください。');
      } else { // defalult
        this.error('ネットワークが接続されていません。ネットワーク接続を確認後、もう一度お試しください。');
      }
    } else {
      this.error('ネットワークが接続されていません。ネットワーク接続を確認後、もう一度お試しください。');
    }
  }
}

export default new ToastUtil();
