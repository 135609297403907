const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', name: 'dashboard', component: () => import('pages/Index.vue') },
      { path: 'notices', name: 'noticelist', component: () => import('pages/notices/List.vue') },
      { path: 'notices/:companyId/:id', name: 'noticedetail', component: () => import('pages/notices/Detail.vue') },
      {
        path: 'search-contents', name: 'contentsearch', component: () => import('pages/search/Search.vue'), props: true,
      },
      { path: 'contents', name: 'contentlist', component: () => import('pages/search/ContentList.vue') },
      { path: 'video/:id', name: 'contentvideodetail', component: () => import('pages/video/Details.vue') },
      { path: 'document/:id', name: 'contentdocumentdetail', component: () => import('pages/document/Detail.vue') },
      { path: 'forgot-password', name: 'forgotpassword', component: () => import('pages/password/ForgetPassword.vue') },
      { path: 'audio/:id', name: 'contentaudiodetail', component: () => import('pages/audio/Detail.vue') },
      { path: 'change-password', name: 'changepassword', component: () => import('pages/change/Password.vue') },
      { path: 'user-setting', name: 'setting', component: () => import('pages/setting/UserSetting.vue') },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
