import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAppStore = defineStore('appStore', () => {
  const loading = ref(false);
  const loadingAu = ref(false);
  const isShowBack = ref(false);
  const pathBack = ref({ path: '/' });
  const currentMenuName = ref('dashboards');
  const previousPath = ref({});

  const setLoading = (flag) => {
    loading.value = flag;
  };

  const setLoadingAu = (flag) => {
    loadingAu.value = flag;
  };

  const setShowBack = (flag) => {
    isShowBack.value = flag;
  };

  const setPathBack = (router) => {
    pathBack.value = router;
  };

  const setMenuName = (name) => {
    currentMenuName.value = name;
  };

  const setPreviousPath = (router) => {
    previousPath.value = router;
  };

  const isLoading = computed(() => loading);
  const isLoadingAu = computed(() => loadingAu);

  return {
    isLoading,
    isLoadingAu,
    isShowBack,
    pathBack,
    currentMenuName,
    previousPath,
    setShowBack,
    setPathBack,
    setLoading,
    setLoadingAu,
    setMenuName,
    setPreviousPath,
  };
});
